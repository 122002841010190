import React from "react"
import ReactMarkdown from "react-markdown"
import { As } from "../../As/As"
import { Badge } from "../../Badge/Badge"
import { Image } from "../../Image/Image"
import { Link } from "../../Link/Link"

const Button = (props) => {
  return (
    <div className="p-1 inline-block">
      <Link
        className={
          props.theme === "primary"
            ? `inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-green-50 font-medium text-center focus:ring-2 border rounded-md shadow-sm`
            : "inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-slate-800 font-medium text-center bg-white hover:bg-slate-100 focus:ring-2 focus:ring-slate-200 focus:ring-opacity-50 border border-slate-200 rounded-md shadow-sm"
        }
        url={props.url}
        rel={props.rel}
      >
        {props.label}
      </Link>
    </div>
  )
}

export function Hero(props) {
  return (
    <section className="relative bg-white overflow-hidden">
      <div className="py-20 md:py-20">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap xl:items-center -mx-4">
            <div className="w-full md:w-1/2 px-4 md:pr-10 mb-16 md:mb-0">
              <Badge component={props.label.component}>
                {props.label.value}
              </Badge>

              <As
                component={props.title.component}
                className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight"
              >
                {props.title.value}
              </As>

              <ReactMarkdown className="mb-8 text-lg md:text-xl text-slate-500 font-medium">
                {props.description}
              </ReactMarkdown>

              <div className="flex flex-wrap">
                {props.buttons?.map((button) => (
                  <Button {...button} key={button.url} />
                ))}
              </div>
            </div>

            <div className="w-full md:w-1/2 md:pl-10 px-4">
              <div className="relative mx-auto md:mr-0 w-full">
                <div className="md:aspect-h-1 md:aspect-w-1">
                  <Image
                    className="relative block rounded-lg w-full object-cover h-full"
                    src={props.image?.src}
                    alt={props.image?.alt}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
