import { graphql } from "gatsby"
import React from "react"
import { useIntl } from "react-intl"
import { Article } from "../components/Article/Article"
import { Category } from "../components/Category/Category"
import { Footer } from "../components/Footer/Footer"
import { FormattedMessage } from "../components/FormattedMessage/FormattedMessage"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Hero } from "../components/Marketing/Hero/Hero"
import { Navbar } from "../components/Navbar/Navbar"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Seo } from "../components/Seo/Seo"
import { ArticleEntity } from "../entities/ArticleEntity"
import { MarketingCategoryType } from "../entities/MarketingEntity"

type Props = {
  data: any
  pageContext: {
    articles: ArticleEntity[]
    category: MarketingCategoryType
    language: string
    languages: any[]
    configuration: any
    langKey: string
    header: any
    footer: any
    drawer: any
    cookies: any
    theme: string
  }
}

const BlogTemplate: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <GlobalWrapper>
      <Seo
        title={intl.formatMessage({ id: "blog/title" })}
        description={intl.formatMessage({ id: "blog/description" })}
        lang={props.pageContext.langKey}
        image={props.data.file.publicURL}
        langUrls={props.pageContext.languages}
      />

      <SelectLanguageModal languages={props.pageContext.languages} />
      <Navbar langKey={props.pageContext.langKey} />
      <Hero
        label={{
          component: "h1",
          value: <FormattedMessage id="blog/articles/categories/label" />,
        }}
        title={{
          component: "h2",
          value: <FormattedMessage id="blog/title" />,
        }}
        description={intl.formatMessage({ id: "blog/description" })}
        buttons={[]}
        image={{ src: props.data.file, alt: "blog" }}
      />
      <Category articles={props.pageContext.articles} />
      
      <Footer lang={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogPageQuery {
    file(base: { eq: "blog.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 896
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      publicURL
    }
  }
`
