import React from "react"
import { As } from "../As/As"
import clsx from "clsx"

export const Badge: React.FC<{
  component: string
  children: React.ReactNode
  disabled?: boolean
  color?: "blue" | "yellow" | "red" | "gray" | "black" | "bronze"
}> = (props) => {
  const color = props.color || "blue"

  return (
    <As
      component={props.component}
      className={clsx(
        `inline-block py-1 px-3 text-xs font-display leading-5 transition-all  ease-in-out duration-300 font-medium uppercase rounded`,
        color === "blue" && !props.disabled && "text-blue-500 bg-blue-100 ",
        color === "blue" &&
          props.disabled &&
          "hover:text-blue-500 hover:bg-blue-100",

        color === "bronze" && !props.disabled && "text-amber-50 bg-amber-800",
        color === "bronze" &&
          props.disabled &&
          "hover:text-amber-500 hover:bg-amber-100",

        color === "yellow" &&
          !props.disabled &&
          "text-yellow-500 bg-yellow-100 ",
        color === "yellow" &&
          props.disabled &&
          "hover:text-yellow-500 hover:bg-yellow-100",

        color === "red" && !props.disabled && "text-red-500  bg-red-100",
        color === "red" &&
          props.disabled &&
          "hover:text-red-500 hover:bg-red-100",

        color === "gray" && !props.disabled && "text-gray-900 0 bg-slate-200",
        color === "gray" &&
          props.disabled &&
          "hover:text-slate-900 hover:bg-slate-200",

        color === "black" && !props.disabled && "text-white  bg-purple-500",
        color === "black" &&
          props.disabled &&
          "hover:text-white hover:bg-purple-500",

        props.disabled &&
          "text-slate-700 bg-slate-200  opacity-40 hover:opacity-100"
      )}
    >
      {props.children}
    </As>
  )
}
