import React, { useContext } from "react"
import { Image } from "../Image/Image"
import dayjs from "dayjs"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { As } from "../As/As"
import { Link } from "../Link/Link"
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon"
import { Badge } from "../Badge/Badge"
import ReactMarkdown from "react-markdown"

type PickedArticle = Pick<
  ArticleEntity,
  | "preview"
  | "url"
  | "author"
  | "title"
  | "updated_at"
  | "description"
  | "category"
>

const Article: React.FC<PickedArticle> = (props) => (
  <div className="w-full md:w-1/2 lg:w-1/3 mb-2">
    <Link
      className="block group rounded-md transition-all ease-in-out duration-300 hover:bg-blue-50 p-4"
      url={props.url}
    >
      <div className="mb-6 overflow-hidden rounded-md">
        <Image
          className="w-full object-cover h-80"
          src={props.preview.src}
          alt={props.preview.alt}
        />
      </div>
      <p className="font-display text-slate-900 font-medium">
        {props.author?.name} • {dayjs(props.updated_at).format("D MMM YYYY")}
      </p>
      <p className="inline-block font-display mb-2 text-2xl transition-colors ease-in-out duration-300 leading-tight text-slate-800 group-hover:text-blue-500 font-bold">
        {props.title}
      </p>
      <p className="mb-4 text-base md:text-lg text-slate-700">
        <ReactMarkdown>
          {props.description.split(" ").slice(0, 50).join(" ")}
        </ReactMarkdown>
      </p>
      <Badge component="span">{props.category.name}</Badge>
    </Link>
  </div>
)

type Props = {
  title?: {
    value: string
    component: string
  }
  name?: string
  description?: string
  label?: {
    value: string
    component: string
  }
  more?: {
    label: string
    url: string
    rel: string
    target: string
  }
  articles: null | PickedArticle[]
}

export const Category: React.FC<Props> = (props) => {
  return (
    <section className="py-8 md:py-20 lg:pb-10">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap items-center mb-8">
          <div className="w-full max-w-5xl">
            {props.label && (
              <Badge component={props.label?.component || "p"}>
                {props.label?.value || props.name}
              </Badge>
            )}

            {props.title && (
              <As
                component={props.title.component}
                className="mb-4 font-display mt-1 text-4xl md:text-5xl leading-tight text-slate-900 font-bold tracking-tighter"
              >
                {props.title.value}
              </As>
            )}

            {props.description && (
              <p className="text-lg md:text-xl text-slate-500 font-medium">
                {props.description}
              </p>
            )}

            {props.more && (
              <div className="w-full mt-4">
                <Link
                  className={`text-lg font-display md:text-xl flex items-center mx-auto  font-medium group`}
                  url={props.more.url}
                >
                  <span className="mr-3">{props.more.label}</span>
                  <ArrowRightIcon className="w-5 h-5 group-hover:translate-x-2 transform duration-300 ease-in-out" />
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap -mx-4">
          {props.articles?.map((article) => (
            <Article
              key={article.url}
              {...article}
              category={{
                name: article?.category?.name || "Blog",
                url: "",
                id: "",
              }}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
